import React from "react";
import { ExpandableCard } from "../../Commons/ExpandableCard/Expandablecard";
import { Paragraph } from "../../Commons/Paragraph";
import { Colors } from "../../Commons/Colors/Colors";
import EyeWhiteIcon from "../../../assets/imgs/eye-white-icon.svg";
import CalenderIcon from "../../../assets/imgs/calender-icon.svg";

const ResearchExpandCard = (props: any) => {
  const { title, subTitle, plus, description, date } = props;

  const getTitle = (title: string) => {
    switch (title) {
      case "seasonRecap":
        return "Season Recap";
      case "seasonOutlook":
        return "Season Outlook";
      case "weeklyRecap":
        return "Weekly Recap";
      case "weeklyOutlook":
        return "Weekly Outlook";
      default:
        return "";
    }
  };
  //TODO: FAHAD move this somewhere common
  const getFormattedDate = (dateStr: string): string => {
    const date = new Date(dateStr);
    const options = { month: 'long' } as const;
    const monthName = new Intl.DateTimeFormat('en-US', options).format(date);
    const day = date.getDate();

    const getOrdinalSuffix = (n: number): string => {
      const j = n % 10,
        k = n % 100;
      if (j === 1 && k !== 11) {
        return n + "st";
      }
      if (j === 2 && k !== 12) {
        return n + "nd";
      }
      if (j === 3 && k !== 13) {
        return n + "rd";
      }
      return n + "th";
    };

    const dayWithSuffix = getOrdinalSuffix(day);

    return `${monthName} ${dayWithSuffix}`;
  };

  return (
    <ExpandableCard
      header={
        <>
          <div className="flex justify-between items-center">
            <div
              className={`transition-colors duration-300 ease-in-out relative z-20 cursor-pointer `}
            >
              <div className="flex items-center gap-2">
                <Paragraph
                  text={getTitle(subTitle)}
                  props={{
                    fontSize: "12px",
                    fontWeight: 500,
                    color: Colors.textLigth,
                  }}
                  ParagraphType="Pragragh22"
                  color="textPrimary"
                />
                <img src={EyeWhiteIcon} alt="" className="w-4 h-4" />
              </div>
              <Paragraph
                text={title}
                props={{ fontSize: "18px", fontWeight: 500 }}
                ParagraphType="Pragragh22"
                color="textPrimary"
              />
            </div>
          </div>
          <div className="flex items-center justify-end gap-[6px]">
            <img src={CalenderIcon} alt="" className="w-4 h-4" />
            <Paragraph
              text={getFormattedDate(date)}
              props={{
                fontSize: "10px",
                fontWeight: 500,
                color: Colors.textLigth,
              }}
              className="!leading-[0px]"
              ParagraphType="Pragragh22"
              color="textPrimary"
            />
          </div>
        </>
      }
    >
      <div className="mt-3">
        <Paragraph
          text={description}
          props={{
            fontSize: "12px",
            fontWeight: 500,
            color: Colors.textLigth,
          }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />
      </div>
    </ExpandableCard>
  );
};

export default ResearchExpandCard;
