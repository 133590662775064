import React, { useState, useEffect } from "react";
import { Layout } from "../Layouts/Layout";
import { Paragraph } from "../Commons/Paragraph";
import { Colors } from "../Commons/Colors/Colors";
import { useNavigate } from "react-router";
import ArrowBackIcon from "../../assets/imgs/arrow-back-left.svg";
import phoneIcon from "../../assets/imgs/phone-icon.svg";
import subscriptionIcon from "../../assets/imgs/subscription.svg";
import notificationIcon from "../../assets/imgs/notification.svg";
import syncIcon from "../../assets/imgs/sync-icon.svg";

import FaQs from "../../assets/imgs/F&Qs.svg";
import feedbackIcon from "../../assets/imgs/feedback.svg";
import privacyIcon from "../../assets/imgs/privacy.svg";
import termsOfUseIcon from "../../assets/imgs/terms-of-use.svg";
import SettingItem from "../Commons/SettingItem/SettingItem";
import restorePurchaseIcon from "../../assets/imgs/restore-purchase.svg";

import FeedbackModal from "../FeedbackModal/FeedbackModal";
import { useApplePaymentAuth } from "../../Context/AppleContext/AppleContext";
import { useSupabaseAuth } from "../../Context/AuthContext/AuthContext";
import { isMobile } from "react-device-detect";

interface profile {
  id?: string | undefined | null;
  user_email?: string | undefined | null;
  phone?: string | undefined | null;
  user_dob?: string | undefined | null;
  user_name?: string | undefined | null;
  avatar_url?: string | undefined | null;
}

const Settings: React.FC = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState();
  const [subscription, setSubscription] = useState("None");
  const [feedbackModal, setFeedbackModal] = useState(false);
  const {
    isAppSubscribedMontly,
    isAppSubscribedYearly,
    restore,
    platform,
    restorePurchase,
  } = useApplePaymentAuth();
  const [currentUser, setCurrentUser] = useState<any>(null);
  const { getUserProfile, getUserData } = useSupabaseAuth();
  const [subscriptionTitle, setSubscriptionTitle] =
    useState<string>("Subscription");

  const navigateBack = () => {
    navigate("/home");
  };

  const dummyAction = () => {
    // Dummy action function
  };

  const fetchCurrentUser = async () => {
    const response = await getUserData();

    if (response) {
      setCurrentUser(response?.data?.user);
    }
  };

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  const fetchProfile = async () => {
    if (
      currentUser &&
      (currentUser?.app_metadata?.provider === "phone" ||
        currentUser?.app_metadata?.provider === "apple")
    ) {
      const userProfile: profile | null | undefined = await getUserProfile(
        currentUser?.id,
      );

      setPhone(currentUser?.phone);
    } else {
      const identity_data = currentUser?.identities?.[0]?.identity_data ?? null;

      setPhone(currentUser?.phone);
    }
    if (!isAppSubscribedMontly && !isAppSubscribedYearly) {
      setSubscription("None");
    } else {
      setSubscription("Subscribed");
    }
  };

  useEffect(() => {
    fetchProfile();
  }, [currentUser]);

  useEffect(() => {
    if (isMobile && platform && platform === "ios") {
      if (isAppSubscribedMontly || isAppSubscribedYearly) {
        setSubscriptionTitle("Subscribed");
      }
    }
  }, [isAppSubscribedMontly, isAppSubscribedYearly, platform]);

  const handleSubscription = () => {
    console.log("Action handleSubscription");
    if (isMobile && platform && platform === "ios") {
      restore();
      if (!isAppSubscribedMontly && !isAppSubscribedYearly) {
        navigate("/chatbot-paywall");
      }
    }
  };

  const handleRestorePurchase = () => {
    console.log("Action handleRestorePurchase");
    if (isMobile && platform && platform === "ios") {
      restorePurchase();
    }
  };

  return (
    <Layout>
      <div className="flex items-center justify-between">
        <div
          className="cursor-pointer rounded-[100px] w-[58px] h-[58px] flex justify-center items-center"
          style={{ background: Colors.backgroundSecundary }}
          onClick={navigateBack}
        >
          <img src={ArrowBackIcon} alt="" />
        </div>
        <Paragraph
          text="Settings"
          props={{ fontSize: "28px", fontWeight: 600, marginLeft: "-45px" }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />
        <div></div>
      </div>
      <div className="mt-5">
        <Paragraph
          text="Account"
          props={{
            fontSize: "18px",
            fontWeight: 600,
            letterSpacing: "-0.5px",
            lineHeight: "14px",
            marginBottom: "16px",
          }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />
        <div
          className="max-w-sm mx-auto text-white rounded-[18px] px-[14px] py-4"
          style={{ backgroundColor: Colors.backgroundSecundary }}
        >
          {/* <SettingItem
            icon={phoneIcon}
            label="Phone Number"
            action={dummyAction}
            text={phone}
            customStyle="pb-5"
          /> */}
          <SettingItem
            icon={subscriptionIcon}
            label={subscriptionTitle}
            action={() => handleSubscription()}
            text={subscription}
            customStyle="pb-5"
          />
          <SettingItem
            icon={notificationIcon}
            label="Notifications"
            action={() => navigate("/Notifications")}
          />
          <SettingItem
            icon={syncIcon}
            label="Sync Leagues"
            action={dummyAction}
            lastItem
            customStyle="pt-5"
            text="Coming Soon!"
          />
        </div>
      </div>
      <div className="mt-4">
        <Paragraph
          text="About"
          props={{
            fontSize: "18px",
            fontWeight: 600,
            letterSpacing: "-0.5px",
            lineHeight: "14px",
            marginBottom: "16px",
          }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />
        <div
          className="max-w-sm mx-auto text-white rounded-[18px] px-[14px] py-4"
          style={{ backgroundColor: Colors.backgroundSecundary }}
        >
          <SettingItem
            icon={FaQs}
            label="FAQs"
            action={() => navigate("/FAQs")}
            customStyle="pb-5"
          />
          <SettingItem
            icon={feedbackIcon}
            label="Feedback"
            action={() => setFeedbackModal(true)}
          />
          <a
            href="https://rotobot.ai/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            <SettingItem
              icon={privacyIcon}
              label="Privacy Policy"
              action={dummyAction}
            />
          </a>
          <a
            href="https://rotobot.ai/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            <SettingItem
              icon={termsOfUseIcon}
              label="Terms of Use"
              action={dummyAction}
            />
          </a>
          <div rel="noreferrer">
            <SettingItem
              icon={restorePurchaseIcon}
              label="Restore Purchase"
              action={handleRestorePurchase}
              lastItem
              customStyle="pt-5"
              arrow={false}
            />
          </div>
        </div>
      </div>
      <FeedbackModal setOpen={setFeedbackModal} open={feedbackModal} />
    </Layout>
  );
};

export default Settings;
