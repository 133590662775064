import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "../.././assets/imgs/arrow-back-left.svg";
import { Colors } from "../Commons/Colors/Colors";
import { Paragraph } from "../Commons/Paragraph";
import { Layout } from "../Layouts/Layout";
import PlayersCard from "./PlayersCard/PlayersCard";
import player_icon from "../../assets/imgs/player_icon.png";
import { useEffect, useState } from "react";
import TableTabs from "../Commons/TableTabs/TableTabs";
import searchIcon from "../../assets/imgs/Search.svg";
import TableCom from "../Commons/Tabel/Table";
import Arrow_trending_up from "../../assets/imgs/arrow-trending-up.svg";
import {
  getROSRankings,
  useROSRankings,
} from "../../Controllers/PlayerRanking";

import { useRankingThisWeek } from "../../Controllers/PlayerRanking";

import { headers, positions, playerDummies, categoriesFilters } from "./data";
import { Category } from "./type";
import StockWatchSidebar from "../StockWatchSidebar/StockWatchSidebar";

// Define the types for the data
type Player = {
  position: string;
  name: string;
  [key: string]: any;
};

type CategoryItem = {
  id: number;
  name: string;
};

type PositionItem = {
  id: number;
  name: string;
};

const defaultPosition = { id: 1, name: "All" };
const rosRankPosition = { id: 2, name: "QB" };
const defaultCategory = { id: 1, name: "This Week" };
const rosRankCategory = { id: 2, name: "ROS Rank" };

const Rankings = () => {
  const navigate = useNavigate();
  const [playerPosition, setplayerPosition] = useState<CategoryItem>({
    id: 1,
    name: "This Week",
  });
  const rankingThisWeek = useRankingThisWeek();
  const rosRankings = useROSRankings();
  const [playersData, setPlayersData] = useState<Player[]>([]);
  const [stockWatchPlayersData, setStockWatchPlayersData] = useState<Player[]>(
    [],
  );

  const [category, setCategory] = useState<CategoryItem>({
    id: 1,
    name: "This Week",
  });
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [positionSelected, setPosicionSelected] = useState<PositionItem>({
    id: 2,
    name: "QB",
  });
  const [searchTerm, setSearchTerm] = useState<string | null | undefined>();

  const fetchDatabyCategory = (category: string) => {
    return category === "ROS Rank" ? rosRankings?.data : rankingThisWeek?.data;
  };

  const fetchaPlayerData = () => {
    const categoryData = fetchDatabyCategory(category.name);
    if (!categoryData?.data) return;

    let filteredPlayers = categoryData.data;

    if (isSearch && searchTerm) {
      filteredPlayers = filteredPlayers.filter(
        (player: any) =>
          player.playerName.toLowerCase().includes(searchTerm.toLowerCase()) &&
          (positionSelected.name === "All" ||
            player.playerPosition === positionSelected.name),
      );
    } else if (positionSelected.name !== "All") {
      filteredPlayers = filteredPlayers.filter(
        (player: any) => player.playerPosition === positionSelected.name,
      );
    }

    setPlayersData(filteredPlayers);
  };

  useEffect(() => {
    fetchaPlayerData();
  }, [
    category,
    positionSelected,
    isSearch,
    searchTerm,
    rankingThisWeek,
    rosRankings,
  ]);
  const [playersCard, setPlayersCard] = useState(playerDummies);

  const handleSelect = (item: CategoryItem) => {
    setplayerPosition(item);
    setCategory(item);
    if (item.name === "ROS Rank") {
      setPosicionSelected({
        id: 1,
        name: "All",
      });
    } else {
      setPosicionSelected({
        id: 2,
        name: "QB",
      });
    }
  };

  const handleSelectPosition = (item: PositionItem) => {
    setPosicionSelected(item);
  };

  const handleSearchBar = () => {
    setPosicionSelected(defaultPosition);
    setCategory(rosRankCategory);
    setIsSearch(prev => !prev);
  };

  const handleFilterSearch = (value: string) => {
    setSearchTerm(value);
  };

  const handleClearSearch = () => {
    setPosicionSelected(rosRankPosition);
    setCategory(defaultCategory);
    setSearchTerm("");
    setIsSearch(prev => !prev);
  };

  useEffect(() => {
    const stockWatchPlayersIds = [
      "joshalle1",
      "justjeff1",
      "patrmaho1",
      "georkitt1",
    ];

    const stockWatchPlayers = playersData.filter((player) => {
      return stockWatchPlayersIds.includes(player.roto_player_id);
    });

    setStockWatchPlayersData(stockWatchPlayers);
  }, [playersData]);

  return (
    <Layout>
      <div className="flex h-full relative -m-5">
        <StockWatchSidebar dummies={playersCard as []} />
        <div className="w-full">
          <div>
            {isSearch && (
              <div className="p-5 flex flex-col">
                <div className="flex flex-col justify-center">
                  <div className="relative flex items-center mr-2">
                    <span className="absolute left-3 text-gray-400">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.9 14.32a8 8 0 111.42-1.42l4.31 4.3a1 1 0 01-1.42 1.42l-4.3-4.31zM8 14a6 6 0 100-12 6 6 0 000 12z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                    <input
                      type="text"
                      placeholder="Search player name..."
                      value={searchTerm ? searchTerm : ""}
                      onChange={(e) => handleFilterSearch(e.target.value)}
                      className={`text-white pl-10 py-2 rounded-lg border border-[${Colors.backgroundSecundary}] focus:outline-none bg-[${Colors.backgroundSecundary}]`}
                      style={{ width: "80%" }}
                    />
                  </div>
                  <button
                    onClick={handleClearSearch}
                    className="absolute right-3 text-white flex items-center"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
            {isSearch === false && (
              <div className="p-5">
                <div className="flex items-center justify-between">
                  <div
                    className="cursor-pointer rounded-full w-14 h-14 flex justify-center items-center"
                    style={{
                      background: Colors.backgroundSecundary,
                    }}
                    onClick={() => navigate("/home")}
                  >
                    <img src={ArrowBackIcon} alt="" />
                  </div>
                  <Paragraph
                    text="Rankings"
                    props={{ fontSize: "22px", marginLeft: "-45px" }}
                    ParagraphType="Paragraph22"
                    color="textPrimary"
                  />
                  <div></div>
                </div>

                {/* <div className="sm:hidden block  mt-7">
                  <Paragraph
                    text="Stock Watch"
                    props={{ fontSize: "18px", fontWeight: "600" }}
                    ParagraphType="Paragraph22"
                    color="textPrimary"
                  />
                </div>
                <div className="sm:hidden mt-4 flex gap-3 overflow-x-auto no-scrollbar -mr-5 pr-5">
                  {playerDummies.map((card, index) => (
                    <div key={index}>
                      <PlayersCard
                        arrowImage={Arrow_trending_up}
                        playerImage={card.url}
                        playerName={card.Name}
                        playerPosition={card.POS}
                        rosRank={card.rosRank}
                        opponent={card.Team}
                        id={card.roto_player_id}
                      />
                    </div>
                  ))}
                </div> */}

                <div className="mt-6 pr-5 overflow-x-auto no-scrollbar">
                  <TableTabs
                    tabItems={
                      playerPosition.name === "ROS Rank"
                        ? positions
                        : positions.slice(1)
                    }
                    tabCustomStyle="w-15.5 h-11 shrink-0"
                    onTabSelect={handleSelectPosition}
                    resetTabs={true}
                  />
                </div>

                <div className="mt-6 flex items-center gap-2">
                  <div
                    className={`shrink-0 w-11 h-11 rounded-full bg-[${Colors.backgroundSecundary}] flex justify-center items-center`}
                    onClick={handleSearchBar}
                  >
                    <img src={searchIcon} alt="" />
                  </div>
                  <div className="flex-1 overflow-x-auto no-scrollbar  pr-5">
                    <TableTabs
                      tabItems={categoriesFilters}
                      tabCustomStyle="h-11 px-7 shrink-0"
                      onTabSelect={handleSelect}
                      renderTabText={(item) =>
                        item.name === "Season Leaders"
                          ? "2023 Season"
                          : item.name
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="flex-1 pb-5">
            <TableCom
              tableHeader={headers}
              data={playersData}
              category={category}
              positionSelected={positionSelected}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Rankings;
