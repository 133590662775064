import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import Null_player from "../../../assets/imgs/null-player-img.svg";
import Small_polygon from "../../../assets/imgs/small_polygon.svg";
import { Link } from "react-router-dom";
import { Paragraph } from "../Paragraph";
import { teamColors } from "../../../config/constants/teamColors";

interface TableHeaderItem {
  headerType: string;
  position?: string;
  headers: string[];
}
import { useNavigate } from "react-router-dom";
import { playerProfileCountViews } from "../../../Controllers/PlayerProfileCache";
import { fetchFieldValue } from "../../../lib/utils";

interface DataItem {
  name: string;
  firstName?: string;
  lastName?: string;
  position: string;
  Opponent?: string;
  consensus?: number | null;
  consensus_pos?: string;
  bye?: number | null;
  team?: string;
  total_ppr_fantasy_points_scored?: number | null;
  FPTS_PPR?: number | null;
  url?: string;
  pass_attempts?: number | null;
  completions?: number | null;
  passing_yards?: number | null;
  passing_tds?: number | null;
  interceptions?: number | null;
  passQBRating?: number | null;
  carries?: number | null;
  rushing_yards?: number | null;
  yards_per_rush_attempt?: number | null;
  rushing_tds?: number | null;
  targets?: number | null;
  receptions?: number | null;
  yards_per_reception?: number | null;
  receiving_tds?: number | null;
  trade_value?: number | null;
  roto_player_id?: string;
  [key: string]: any;
}

interface Category {
  name: string;
}

interface PositionItem {
  id: number;
  name: string;
}

interface Props {
  tableHeader: TableHeaderItem[];
  data: DataItem[];
  category: Category;
  positionSelected: PositionItem;
}

const TableCom: React.FC<Props> = ({
  tableHeader,
  data,
  category,
  positionSelected,
}) => {
  const findHeadersByTypeAndPosition = (
    headerType: string,
    position: string,
  ) => {
    if (position === "All") {
      return tableHeader.find((element) => element.headerType === headerType);
    }
    const headers = tableHeader.find(
      (element) => element.headerType === headerType,
    );
    return headers
      ? headers
      : tableHeader.find((element) => element.headerType === headerType);
  };

  const specificHeaders = findHeadersByTypeAndPosition(category.name, "");
  const navigate = useNavigate();

  console.log("Specific Headers: ", specificHeaders);
  console.log("Data: ", data);

  const fetchFieldName = (item: DataItem) => {
    return item.playerName ? item.playerName : null;
  };

  const onClickCell = async (playerId: string) => {
    await playerProfileCountViews(playerId);
    navigate(`/player-profile/${playerId}`);
  };

  return (
    <div className="">
      {data?.length > 0 ? (
        <Table className=" overflow-x-auto w-full">
          <TableHeader>
            <TableRow className="hover:bg-transparent border-transparent">
              {specificHeaders?.headers &&
                specificHeaders.headers.map((item, index) => (
                  <TableHead
                    key={"players-header-" + index}
                    className="text-white text-[14px] pb-2"
                    style={index !== 0 ? { minWidth: "150px" } : {}}
                  >
                    {item}
                  </TableHead>
                ))}
            </TableRow>
          </TableHeader>
          <TableBody className={`[&>*:nth-child(odd)]:bg-[#FFFFFF1A]`}>
            {data.map((item, index) => (
              <TableRow
                key={"player-body-" + index}
                className={`cursor-pointer hover:bg-transparent border-transparent bg-transparent text-white`}
                onClick={() => onClickCell(item.roto_player_id as string)}
              >
                <TableCell
                  key={"player-cell-name-" + index}
                  className="text-base font-medium flex items-center gap-3"
                  style={{ width: "240px" }}
                >
                  <div className="relative">
                    <div
                      className="rounded-xl w-[49.2px] h-[49.2px] flex items-end overflow-hidden"
                      style={{
                        backgroundColor: item.playerTeamShort
                          ? teamColors[item.playerTeamShort]
                          : "#4F2683",
                      }}
                    >
                      <img
                        className={
                          item.playerHeadshotURL ? "scale-[1.8]" : "h-[45px]"
                        }
                        src={item.playerHeadshotURL || Null_player}
                        alt=""
                      />
                    </div>
                    <div className="absolute -bottom-0 -right-[10px]">
                      <div className="relative">
                        <img
                          src={Small_polygon}
                          alt=""
                          className="w-[20px] h-[20px]"
                        />
                        <Paragraph
                          text={item.playerPosition}
                          props={{
                            fontSize: "8px",
                            lineHeight: "100%",
                            fontWeight: "600",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                          ParagraphType="Pragragh22"
                          color="textPrimary"
                        />
                      </div>
                    </div>
                  </div>
                  {fetchFieldName(item)}
                </TableCell>

                {specificHeaders?.headers.slice(1).map((header, idx) => (
                  <TableCell
                    key={`cell-${index}-${idx}`}
                    style={{ width: "100px" }} 
                  >
                    {fetchFieldValue(item, header)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <div className="flex justify-center mt-10">
          <Paragraph
            text={"--No Data Currently--"}
            props={{
              fontSize: "20px",
              lineHeight: "145%",
              letterSpacing: "-0.4px",
              opacity: "0.70",
            }}
            ParagraphType="Pragragh22"
            color="textPrimary"
          />
        </div>
      )}
    </div>
  );
};

export default TableCom;
