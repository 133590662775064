import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApplePaymentAuth } from "../Context/AppleContext/AppleContext";
import { isMobile } from "react-device-detect";
import { FirebaseRemoteConfig } from "@capacitor-firebase/remote-config";

import { initializeApp } from "firebase/app";
import { getRemoteConfig as getRemoteConfigWeb } from "firebase/remote-config";
import { getValue } from "firebase/remote-config";
import { fetchAndActivate as fetchAndActivateWeb } from "firebase/remote-config";

const usePaywall = () => {
  const navigate = useNavigate();
  const { isAppSubscribedMontly, isAppSubscribedYearly, platform } =
    useApplePaymentAuth();
  const [paywallEnabledIOS, setPaywallEnabledIOS] = useState<boolean>(false);
  const [paywallEnabledWeb, setPaywallEnabledWeb] = useState<boolean>(false);
  const environment: string = process.env.REACT_APP_ENV || "prod";
  const postFix = environment === "staging" ? "_stg" : "_prod";
  const paywallEnabled = `paywallEnabled${postFix}`;
  const paywallEnabledMobile = `paywallEnabledMobile${postFix}`;

  const firebaseConfig = {
    apiKey: "AIzaSyDgwumdff20OvuC4lgZc1-FuiAVuH8LmDc",
    authDomain: "rotobot-ai.firebaseapp.com",
    projectId: "rotobot-ai",
    storageBucket: "rotobot-ai.appspot.com",
    messagingSenderId: "62104652584",
    appId: "1:62104652584:web:f219756c3a769f2aa2de1d",
    measurementId: "G-JHP3WK1GPS",
  };

  const app = initializeApp(firebaseConfig);
  const remoteConfig = getRemoteConfigWeb(app);
  remoteConfig.settings.minimumFetchIntervalMillis = 6000;

  const payWall = async (payWallValue: boolean) => {
    if (isMobile && platform && platform === "ios") {
      if (!payWallValue) {
        return;
      } else {
        if (!isAppSubscribedMontly && !isAppSubscribedYearly) {
          navigate("/chatbot-paywall");
        }
      }
    } else {
      if (payWallValue) {
        navigate("/chatbot-paywall");
      }
    }
  };

  FirebaseRemoteConfig.fetchConfig({
    minimumFetchIntervalInSeconds: 0,
  });

  FirebaseRemoteConfig.fetchAndActivate().then(() => {
    if (platform && platform === "web") {
      FirebaseRemoteConfig.getBoolean({
        key: paywallEnabled,
      })
        .then((data: any) => {
          console.log("Returned object", data);
          setPaywallEnabledWeb(data.value);
          payWall(data.value);
        })
        .catch((err: any) => {
          setPaywallEnabledWeb(false);
          payWall(false);
          console.log("Error getting remote data", err);
        });
    }

    if (isMobile && platform && platform === "ios") {
      FirebaseRemoteConfig.getBoolean({
        key: paywallEnabledMobile,
      })
        .then((data: any) => {
          setPaywallEnabledIOS(data.value);
          console.log("Returned object", data);
          payWall(data.value);
        })
        .catch((err: any) => {
          setPaywallEnabledIOS(false);
          payWall(false);
          console.log("Error getting remote data", err);
        });
    }
  });

  // useEffect(() => {

  //   payWall();
  // }, [isMobile, isAppSubscribedMontly, isAppSubscribedYearly]);
};

export default usePaywall;
