import React from "react";
import ResearchExpandCard from "../ResearchExpandCard/ResearchExpandCard";

interface Insight {
  BlurbTitle: string | null;  
  ExtendedBlurb: string | null;
  LastProccessedTime: string | null;
}

interface InsightsOverview {
  seasonRecap: Insight | null;
  seasonOutlook: Insight | null;
  weeklyRecap: Insight | null;
  weeklyOutlook: Insight | null;
}

interface Data {
  insightsOverview: InsightsOverview | null;
}

const ResearchOverview: React.FC<{ data: Data }> = ({ data }) => {
  return (
    <div className="mt-5">
      {data?.insightsOverview ? (
        Object.entries(data.insightsOverview).map(([key, value]) => {
          if (
            value &&
            value.BlurbTitle &&
            value.ExtendedBlurb
          ) {
            return (
              <div key={key}>
                <ResearchExpandCard
                  title={value.BlurbTitle}
                  subTitle={key}
                  plus={value.BlurbTrend === "+"}
                  description={value.ExtendedBlurb}
                  date={value.LastProccessedTime}
                />
              </div>
            );
          }
          return null;
        })
      ) : (
        <div className="text-[22px] font-medium">-- No Data --</div>
      )}
    </div>
  );
};

export default ResearchOverview;
