import { Paragraph } from "../../Commons/Paragraph";

//icons
import ArrowBackIcon from "../../../assets/imgs/arrow-back-left.svg";
import MenuLineIcon from "../../../assets/imgs/menu-line.svg";

//style
import { HeaderStyle } from "./HeaderStyle";

import { HeaderProps } from "./type";
import { useNavigate, useParams } from "react-router-dom";
import { Colors } from "../../Commons/Colors/Colors";

export const Header: React.FC<HeaderProps> = ({ onClickMenu }) => {
  const navigate = useNavigate();
  const { id, playerId } = useParams();
  
  return (
    <div className="flex items-center justify-between px-5 pt-5">
      <div
        className="cursor-pointer relative z-10"
        style={HeaderStyle.icon}
        onClick={() => {
          if(playerId){
            navigate(`/player-profile/${playerId}`);
          }
          else{
            navigate("/home");
          }

        }}
      >
        <img src={ArrowBackIcon} alt="" />
      </div>
      <div className=" text-[22px] font-medium chatbot-header-text">
        RotoBot v1.0
      </div>
      <div
        className="cursor-pointer relative z-10"
        style={HeaderStyle.icon}
        onClick={onClickMenu}
      >
        <img src={MenuLineIcon} alt="" />
      </div>
      {/* <div className="text-xl"> Icono 2</div> */}
    </div>
  );
};
